<template>
  <!-- 新建方案 -->
  <el-dialog
    :visible.sync="dialogTableVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleClose"
    width="700px"
  >
    <div slot="title" class="dialog-header">
      <span>添加异常平台</span>
    </div>
    <div
      class="dialog-body"
      v-loading="btnloading"
      element-loading-text="正在努力提交中！"
      element-loading-spinner="el-icon-loading"
    >
      <!-- *数据范围 -->
      <div class="dialog-body-desc m-t-16"> 
        <span class="redtext"></span>平台类型
      </div>
      <div class="flex m-t-16">
        <el-checkbox
          :indeterminate="PlatformObj.isIndeterminate"
          v-model="PlatformObj.checkAll"
          @change="CheckAllFn(1, $event)"
          >全选</el-checkbox
        >
        <el-checkbox-group
          class="m-l-16"
          v-model="PlatformObj.checkedform"
          @change="selectCheckAllFn(1, $event)"
          
        >
          <el-checkbox
            v-for="(item, index) in PlatformObj.checkedData"
            :label="item"
            :key="index"
            :disabled="ifdisabled(index)"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <!-- *账号名称 -->
      <div class="dialog-body-desc m-t-16">
        <span class="redtext">*</span> 账号名称
      </div>
      <div class="flex m-t-16">
        <el-select
          v-model="copyObj"
          filterable
          remote
          clearable
          reserve-keyword
          placeholder="请选择平台后搜索账号名称"
          @clear="remoteMethod"
          :remote-method="remoteMethod"
          :loading="loading"
          value-key="accountId"
        >
          <el-option
            v-for="item in options"
            :key="item.accountId"
            :label="item.accountName"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <el-radio-group v-model="redio" class="m-t-big" @input="change(redio)">
        <el-radio :label="1">整站篡改</el-radio>
        <el-radio :label="2">添加有害信息</el-radio>
      </el-radio-group>

      <!--整站篡改-->
      <template v-if="redio == 1">
        <el-upload
          class="upload-demo"
          :action="baseURL + actionUrl"
          :headers="{ Authorization: token }" 
          :on-success="handleAvatarSuccess"
          :on-error="$main.handleAvatarError"
          :before-upload="$main.beforeAvatarUpload"
          :on-remove="handleChange"
          :multiple="false"
          :limit="1"
          :file-list="fileList"
        >
          <div
            class="cursor dialog-body-desc m-t-16"
            style="color: #f58030"
          >
            <img
              src="../../../assets/img/gjh/jiahao.png"
              width="10px"
              alt=""
              srcset=""
            />
            上传截图
          </div>
        </el-upload>
      </template>
      <!-- 添加有害信息 -->
      <template v-if="redio == 2">
        <!-- 监测方式 -->
        <div
          class="cursor dialog-body-desc m-t-16"
          style="color: #f58030"
          @click="addLiebaio"
        >
          <img
            src="../../../assets/img/gjh/jiahao.png"
            width="10px"
            alt=""
            srcset=""
          />
          添加有害信息
        </div>
        <div
          class="harmful-box m-t-16"
          v-for="(item, inx) in copyharmfulDataObj"
          :key="inx"
        >
          <el-row :gutter="20">
            <el-col :span="11">
              <div class="dialog-body-desc">
                <span class="redtext">*</span> 标题
              </div>
              <el-input
                class="m-t-16"
                v-model.trim="item.title"
                placeholder="请输入标题"
                :maxlength="$main.maxlength(500)"
                show-word-limit
              ></el-input>
            </el-col>
            <el-col :span="11">
              <div class="dialog-body-desc">
                <span class="redtext">*</span> 作者
              </div>
              <el-input
                class="m-t-16"
                v-model.trim="item.author"
                placeholder="请输入作者"
              ></el-input>
            </el-col>
            <el-col :span="11" class="m-t-16">
              <div class="dialog-body-desc">
                <span class="redtext">*</span> 有害类型
              </div>
              <el-select
                class="select m-t-16"
                v-model.trim="item.messageTypeList"
                multiple
                collapse-tags
                placeholder="请选择"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="11" class="m-t-16">
              <div class="dialog-body-desc">
                <span class="redtext">*</span> 发文时间
              </div>
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                class="select m-t-16"
                v-model="item.publishTime"
                type="datetime"
                clearable
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-col>
            <el-col :span="24" class="m-t-16">
              <div class="dialog-body-desc">
                <span class="redtext">*</span> 原文链接
              </div>
              <el-input
                class="m-t-16"
                v-model="item.sourceUrl"
                placeholder="请输入原文链接"
              ></el-input>
            </el-col>
            <el-col :span="24" class="m-t-16">
              <div class="dialog-body-desc">正文</div>
              <el-input
                class="m-t-16"
                v-model="item.content"
                placeholder="请输入正文"
              ></el-input>
            </el-col>
          </el-row>
          <img
            class="img"
            src="../../../assets/img/gjh/sc.png"
            @click="shanchuliebiao(inx)"
            alt=""
          />
        </div>
      </template>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消 </el-button>
      <el-button @click="okFn" :loading="btnloading" type="primary"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template> 

<script>
import { queryPage } from "../../../api/platformaccount/index";
export default {
  data() {
    return {
      baseURL: window.localStorage.getItem('baseURL'),
      actionUrl:window.localStorage.getItem('actionUrl'),
      token: "Bearer " + window.localStorage.getItem("token"),
      fileList: [], // 上传文件的数组
      btnloading: false, // btn按钮
      copyObj: {},
      loading: false,
      options: [], // 在平台下搜索
      // 平台类型 || 数据范围
      PlatformObj: {
        checkAll: false, // 平台类型全选
        checkedform: [], //平台类型已选
        checkedData: JSON.parse(window.localStorage.getItem("platform_type")), //平台类型 可选数据
        isIndeterminate: false, //平台类型不确定状态
      },
      //   异常状态
      statusList: JSON.parse(window.localStorage.getItem("message_type")) || [],
      redio: 1, // 方式
      dialogTableVisible: false, // 弹窗开关
      myData: {
        accountId: null, 
        // accountName: "", // 账号名称
        // messageTypes:null ,// 异常状态
        platformType: null, // 平台类型
        harmfulDataList: [], // 有害信息列表
        abnormalImgUrl: "", //异常图片Url
      },
      // 有害信息输入框
      copyharmfulDataObj: [
        {
          author: "", // 作者
          content: "", // 正文
          title: "", // 标题
          messageTypeList: [], // 有害类型
          publishTime: '', //发文时间
          
          sourceUrl: "", // 原文链接
        },
      ],
    };
  },
  mounted() {
    this.PlatformObj.checkedform = this.PlatformObj.checkedData.filter(v=>v.value == 1)
    this.PlatformObj.isIndeterminate = true
    this.remoteMethod()
  },
  methods: {
    change(redio) {
      if(redio == 1) {
         const ifWz = this.PlatformObj.checkedform.filter((v) => v.value == 1)
         if(ifWz.length > 0) {
          // this.PlatformObj.checkedform  = ifWz
         }else{
          this.PlatformObj.checkedform = this.PlatformObj.checkedData.filter((v) => v.value == 1)
         }
      }
    },
    ifdisabled(inx) {
      let ifOk = null
      if(this.redio == 1) {
        if(inx !== 0) {
          ifOk = true
        }
      }
      return ifOk
    },
       // 上传文件成功
       handleAvatarSuccess(res) {
      const obj =
        res.code == 200
          ? { message: "上传成功！", type: "success" }
          : { message: "上传失败" + res.msg, type: "error" };
      this.$message(obj);
      if (res.code == 200) this.fileList.push(res.data);
    },
    // 控制上传列表(删除)
    handleChange(fileList) {
      this.fileList.forEach((x, y) => {
        if (fileList.uid == x.uid) {
          this.fileList.splice(y, 1);
        }
      });
    },
    // 删除列表
    shanchuliebiao(inx) {
      if (this.copyharmfulDataObj.length == 1) {
        this.$message.warning("请至少保留一条有害信息列表！");
        return;
      }
      this.copyharmfulDataObj.splice(inx, 1);
    },
    addLiebaio() {
      this.copyharmfulDataObj.push({
        author: "", // 作者
        content: "", // 正文
        title: "", // 标题
        messageTypeList: [], // 有害类型
        publishTime: [], //发文时间
        // publishTime:[],
        sourceUrl: "", // 原文链接
      });
    },
    async remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const {
          PlatformObj: { checkedform },
        } = this;
        
        const obj = {
          platformTypeList: checkedform.map((v) => v.value),
          accountName: query, 
        };
        if(this.redio ==1) {
        console.log(obj.platformTypeList)
          obj.platformTypeList = obj.platformTypeList.filter(v=> v== 1)
          
        }
        const Res = await queryPage(obj);
        if (Res && Res.data && Res.data.code == 200) {
          this.loading = false;
          this.options = Res.data.data.list;
        }
      } else {
        this.options = [];
      }
    },
    // 打开弹出框
    dakai() {
      this.dialogTableVisible = true;
    },

    // 全选按钮
    CheckAllFn(idx, val) {
      this.$main.selectAll(
        val,
        ...{
          1: [this.PlatformObj], // 平台类型
          2: [this.subjectObj], // 主体类型
        }[idx]
      );
    },
    // 多选按钮
    selectCheckAllFn(idx, { length }) {
      this.$main.selections(
        length,
        ...{
          1: [this.PlatformObj], // 平台类型
          2: [this.subjectObj], // 主体类型
        }[idx]
      );
    },
    // 提交
    okFn() {
      const { myData, copyharmfulDataObj, copyObj, redio } = this;
      // 判断
      if (!copyObj.accountId) {
        this.$message.warning("请选择账号名称！");
        return;
      }
      // 整站篡改 || 上传图片
      if(redio == 1) {
        if(this.fileList.length == 0) {
          this.$message.warning("请上传截图！")
          return
        }
        myData.abnormalImgUrl = this.fileList[0].url
      }

      // 添加有害信息
      if (redio == 2) {
        let ifError = true;
        copyharmfulDataObj.forEach((v) => {
          if (!v.title) {
            this.$message.warning("请输入标题！");
            ifError = false;
            return;
          }
          if (!v.author) {
            this.$message.warning("请输入作者！");
            ifError = false;
            return;
          }

          if (!v.messageTypeList || v.messageTypeList.length == 0) {
            this.$message.warning("请选择有害类型！");
            ifError = false;
            return;
          }
          if (!v.publishTime || v.publishTime.length == 0) {
            this.$message.warning("请选择发文时间！");
            ifError = false;
            return;
          }else{
            v.createTime=v.publishTime
          }
          if (!v.sourceUrl) {
            this.$message.warning("请输入原文链接！");
            ifError = false;
            return;
          }
        });
        if (!ifError) return;
      }
      this.btnloading = true;
      // 配置
      const {districtId,cityId,provinceId} = copyObj
      copyharmfulDataObj.forEach((v) => {
        v.messageTypeList = v.messageTypeList.map((v) => Number(v));
      });
      let copyList = []
      copyharmfulDataObj.forEach(v=> {
        copyList = [...copyList,{...v,districtId,cityId,provinceId}]  
      })
      myData.platformType = copyObj.platformType; 
      myData.accountId = copyObj.accountId;
      myData.harmfulDataList = copyList;
      if(redio == 1) {
        delete myData.harmfulDataList
      }
      if(redio == 2) {
        delete  myData.abnormalImgUrl 
      }
      this.$emit("okFn", myData); // type 1：保存 2：编辑
    },
    async handleClose() {
      this.dialogTableVisible = false;
      this.btnloading = false;
      const {
        myData,
        copyharmfulDataObj,
        copyObj,
        redio,
        PlatformObj,
        options,
        fileList
      } = this;
      await this.$main.mySetTimeOut(300); 
      this.$main.setReset(
        {
          myData,
          copyharmfulDataObj,
          copyObj,
          redio,
          // PlatformObj,
          options,
          fileList
        },
        this
      );
      this.remoteMethod()
    },
  },
};
</script>

<style lang="scss" scoped >
::v-deep,
.is-disabled{
   .is-disabled{
    .el-checkbox__inner{
      // border-color: #C0C4CC !important;
    background-color: #C0C4CC  !important;
} 
  }
}

.select{
 width: 100%;
}
.redtext {
  color: #ea3342;
}
.harmful-box {
  // 有害信息列表
  width: 100%;
  height: auto;
  padding: 24px 16px;
  background: #f5f6fa;
  border-radius: 4px;
  position: relative;
  // background-image:url('../../../assets/img/gjh/sc.png') ;
  // background-position:100% -4%;;
  // background-repeat: no-repeat;
  .img {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
}
</style>